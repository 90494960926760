<template>
  <div :class="`${buttonOrientationClass} my-sm mx-sm lg:mx-0 touch:md:mx-0`">
    <div
      class="inline-block custom-button rounded-alt-2xl custom-button-slim"
      :class="[typeClasses, props.buttonClass]"
    >
      <NuxtLink
        v-if="props.blockData.scrollToForm"
        class="block text-center"
        @click="scrollToForm()"
      >
        <FaIcon
          v-if="hasIconLeft"
          icon-class="fas fa-chevron-left"
          classes="ml-xs fa-fw"
        />
        {{ blockData.label }}
        <FaIcon
          v-if="hasIconRight"
          icon-class="fas fa-chevron-right"
          classes="mr-xs fa-fw"
        />
      </NuxtLink>
      <NuxtLink
        v-else
        class="block text-center"
        :to="blockData.uri"
        :target="blockData.target"
      >
        <FaIcon
          v-if="hasIconLeft"
          icon-class="fas fa-chevron-left"
          classes="ml-xs fa-fw"
        />
        {{ blockData.label }}
        <FaIcon
          v-if="hasIconRight"
          icon-class="fas fa-chevron-right"
          classes="mr-xs fa-fw"
        />
      </NuxtLink>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { scrollToElement } from '@/helpers/scroll';
import { ECallToActionBtnType } from '@/@types/call-to-action-btn-type';
import type { CTAData } from './blockTypes';
import FaIcon from '@/components/fa-icon.vue';

const props = defineProps({
  blockData: {
    type: Object as PropType<CTAData>,
    required: true,
    default: {} as CTAData,
  },
  buttonClass: {
    type: String,
    required: false,
    default: () => {
      return '';
    },
  },
});

const btnType = props.blockData.type ?? ECallToActionBtnType.PRIMARY;
const hasIconLeft = btnType === ECallToActionBtnType.ICON_TEXT_LINK;
const hasIconRight = btnType === ECallToActionBtnType.TEXT_LINK_ICON;

const buttonOrientationClass = computed(() => {
  return 'text-' + props.blockData.btnOrientation;
});

const scrollToForm = () => {
  const formElements = document.querySelectorAll('.scroll-to-form');
  if (formElements.length > 0) {
    scrollToElement(formElements[0], 20);
  }
  return;
};

const typeClasses = {
  '!text-white !font-medium': props.blockData.textInverted ?? false,
  '!font-normal':
    !props.blockData.textInverted &&
    (btnType === ECallToActionBtnType.ICON_TEXT_LINK ||
      btnType === ECallToActionBtnType.TEXT_LINK_ICON ||
      btnType === ECallToActionBtnType.TEXT_LINK),
  'bg-btn-primary-base !text-white !font-medium':
    btnType === ECallToActionBtnType.PRIMARY,
  'bg-btn-primary-base-opc50 text-text-base':
    btnType === ECallToActionBtnType.SECONDARY,
  'relative overflow-hidden text-primary-base transition-all rounded-alt-2xl !p-0 place-items-center after:content-[none]':
    btnType === ECallToActionBtnType.ICON_TEXT_LINK ||
    btnType === ECallToActionBtnType.TEXT_LINK_ICON ||
    btnType === ECallToActionBtnType.TEXT_LINK,
  'bg-btn-cta-base text-white !font-medium':
    btnType === ECallToActionBtnType.MAIN_PATH,
};
</script>
